import {
    Sigma,
    RandomizeNodePositions,
    RelativeSize,
    ForceAtlas2,
} from 'react-sigma';

let myGraph = {
    nodes: [
        { id: 'n1', label: 'Alice' },
        { id: 'n2', label: 'Rabbit' },
    ],
    edges: [{ id: 'e1', source: 'n1', target: 'n2', label: 'SEES' }],
};

export const PersonaNetworkGraph = () => {
    const sizeBg = 10;
    const sizeMd = 8;
    const sizeSm = 6;

    const persona = {
        nodes: [
            { id: 'korean', label: 'Korean', size: sizeSm, color: '#C7FFD8' },
            {
                id: 'abroad',
                label: 'Study Abroad Since 15',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'pixels',
                label: 'Love Pixels...',
                size: sizeSm,
                color: '#C7FFD8',
            },

            {
                id: 'experiences',
                label: 'Experiences',
                size: sizeBg,
                color: '#009259',
            },
            {
                id: 'autodesk',
                label: 'Autodesk',
                size: sizeMd,
                color: '#6AEF92',
            },
            { id: 'autocad', label: 'AutoCAD', size: sizeSm, color: '#6AEF92' },
            {
                id: 'desktopapp',
                label: 'Autodesk Desktop Application',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'vault',
                label: 'Vault, File Managing App',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'automotive',
                label: 'Automotive UX',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'system',
                label: 'Design System',
                size: sizeSm,
                color: '#C7FFD8',
            },
            { id: 'sponet', label: 'Sponet', size: 5, color: '#6AEF92' },
            {
                id: 'mobileapp',
                label: 'Mobile Application',
                size: sizeSm,
                color: '#C7FFD8',
            },
            { id: 'startup', label: 'Startup', size: sizeSm, color: '#C7FFD8' },

            {
                id: 'teamplayer',
                label: 'Team Player',
                size: sizeMd,
                color: '#6AEF92',
            },
            {
                id: 'help',
                label: 'Love to Help',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'adaptable',
                label: 'Adaptable',
                size: sizeSm,
                color: '#C7FFD8',
            },
            {
                id: 'inclusive',
                label: 'Inclusive',
                size: sizeSm,
                color: '#C7FFD8',
            },

            { id: 'skills', label: 'Skills', size: sizeBg, color: '#009259' },
            {
                id: 'research',
                label: 'UX Research',
                size: sizeMd,
                color: '#6AEF92',
            },
            {
                id: 'solving',
                label: 'Problem Solving',
                size: sizeMd,
                color: '#6AEF92',
            },
            {
                id: 'thinking',
                label: 'Design Thinking',
                size: sizeMd,
                color: '#6AEF92',
            },
            {
                id: 'product',
                label: 'Product Design',
                size: sizeMd,
                color: '#6AEF92',
            },
            {
                id: 'strategy',
                label: 'Design Strategy',
                size: sizeMd,
                color: '#6AEF92',
            },
        ],
        edges: [
            { id: 'e1', source: 'experiences', target: 'autodesk', weight: 10 },
            { id: 'e11', source: 'autodesk', target: 'autocad' },
            { id: 'e12', source: 'autodesk', target: 'desktopapp' },
            { id: 'e13', source: 'autodesk', target: 'vault' },
            { id: 'e14', source: 'autodesk', target: 'automotive' },
            { id: 'e15', source: 'autodesk', target: 'system' },

            { id: 'e2', source: 'experiences', target: 'sponet', weight: 10 },
            { id: 'e21', source: 'sponet', target: 'mobileapp' },
            { id: 'e22', source: 'sponet', target: 'startup' },

            { id: 'e31', source: 'teamplayer', target: 'help' },
            { id: 'e32', source: 'teamplayer', target: 'adaptable' },
            { id: 'e33', source: 'teamplayer', target: 'inclusive' },

            { id: 'e41', source: 'skills', target: 'research' },
            { id: 'e42', source: 'skills', target: 'solving' },
            { id: 'e43', source: 'skills', target: 'thinking' },
            { id: 'e44', source: 'skills', target: 'product' },
            { id: 'e45', source: 'skills', target: 'strategy' },
        ],
    };

    return (
        <Sigma
            graph={persona}
            settings={{ drawEdges: true, clone: false }}
            renderer="svg">
            <RandomizeNodePositions />
            <ForceAtlas2
                iterationsPerRender={1}
                barnesHutTheta={1000}
                linLogMode={true}
                // scalingRatio={3}
                edgeWeightInfluence={1}
                slowDown={10}
                strongGravityMode
                gravity={0.8}
                timeout={50000}
                worker
            />
            {/* <RelativeSize initialSize={15}/> */}
        </Sigma>
    );
};
