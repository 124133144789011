import React from 'react';

import ReactDOM from 'react-dom/client';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import { About } from './About';
import { PreviewPdf } from './Components';
import { Home } from './Home';
import { LandingLayout, PageLayout, ProjectLayout } from './Layout';
import { ModalLayout } from './Modal';
import { Alias } from './ProjectAlias';
import { Onboarding } from './ProjectOnboarding';
import { Panel } from './ProjectPanel';
import { Tab } from './ProjectTab';
import './css/styles.css';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ModalLayout />}>
            <Route element={<PageLayout />}>
                <Route element={<LandingLayout />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/about" element={<About />} />
                </Route>
                <Route element={<ProjectLayout />}>
                    <Route
                        exact
                        path="/projects/alias-icon-modernization"
                        element={<Alias />}
                    />
                    <Route
                        exact
                        path="/projects/alias-new-onboarding-screen"
                        element={<Onboarding />}
                    />
                    <Route
                        exact
                        path="/projects/panel-component-design-system"
                        element={<Panel />}
                    />
                    <Route
                        exact
                        path="/projects/autocad-tab-design-system"
                        element={<Tab />}
                    />
                </Route>
            </Route>
            {/* <Route
                exact
                path="/resume"
                element={<PreviewPdf href="/jieunjaykim-resume.pdf" />}
            /> */}
        </Route>,
    ),
);

ReactDOM.createRoot(document.getElementById('root')).render(
    // Uncomment in production environment.
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
