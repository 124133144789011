import { ProjectBanner, ProjectTitle, Tag } from './Components';
import { Carousel, LaptopFrame, NoFrame } from './Frames';
import Banner from './img/onboarding/banner.png';
import Carousel11 from './img/onboarding/carousel1-0.onboardingscreen.png';
import Carousel12 from './img/onboarding/carousel1-1.Detail page.png';
import Carousel13 from './img/onboarding/carousel2-0.Within application (docked ver.).png';
import Carousel14 from './img/onboarding/carousel2-1.Within application - detail page (docked ver.).png';
import Carousel15 from './img/onboarding/carousel3-0.Within application(docked ver. minimum size).png';
import Carousel16 from './img/onboarding/carousel3-1.Within application - detail page(docked ver. minimum size).png';
import Carousel17 from './img/onboarding/carousel3-2.Within application - detail page(docked ver. minimum size).png';
import UserFlow from './img/onboarding/flow.png';
import NewUserFlow from './img/onboarding/newflow.png';
import NewOnboarding from './img/onboarding/newonboarding.png';
import CurrentStatus from './img/onboarding/status.png';
import { ReactComponent as Accessibility } from './img/svg/Accessibility.svg';
import { ReactComponent as Autodesk } from './img/svg/Autodesk.svg';
import { ReactComponent as Enhancement } from './img/svg/Enhancement.svg';
import { ReactComponent as Userfriendly } from './img/svg/Userfriendly.svg';
import {
    Row48,
    Column6,
    Row,
    PageEnd,
    Card,
    CardTitle,
    Column4,
    Title,
} from './Layout';
import KeyFeatureVideo2 from './video/onboarding/essentialSkills-localization.mp4';
import KeyFeatureVideo4 from './video/onboarding/flexible.mp4';
import KeyFeatureVideo1 from './video/onboarding/learning.mp4';
import KeyFeatureVideo3 from './video/onboarding/SearchFeature.mp4';

export const Onboarding = () => (
    <div className="row gy-xxl">
        <ProjectBanner src={Banner} alt="Alias" />

        <ProjectTitle title="Alias New Onboarding Screen">
            <div className="row gx-xs gy-xs">
                <div className="col-auto">
                    <Tag text="Product Design" />
                </div>
                <div className="col-auto">
                    <Tag text="Visual Design" />
                </div>
            </div>
        </ProjectTitle>
        <Row48 title="Overview">
            Alias, known for its stiff learning curve and being hard to learn
            for first-time users, required a more intuitive onboarding
            experience. While there is a tutorial site available, many users
            struggled to find and access it. The onboarding process was
            redesigned to enhance user experience by providing easy access to
            learning materials and aligning the interface with other Autodesk
            products. This redesign aims to support new users in their initial
            interaction with the software, making the learning curve less steep
            and improving overall user satisfaction.
        </Row48>

        <Row48 title="Duration">Dec 2022 - Feb 2023</Row48>

        <Row48 title="Problem">
            The poor connection between the Alias application and the learning
            site created a significant barrier for many users, particularly
            first-time users. This lack of easy access to educational resources
            hindered their ability to learn and use the software effectively.
            Additionally, we identified an opportunity to introduce new features
            and update existing ones to better meet user needs and expectations.
        </Row48>

        <Row48 title="Role">
            As the product designer on this project, I was responsible for
            creating a seamless and engaging onboarding experience for new users
            of Alias. My role encompassed several key tasks:
            <br />
            <br />
            <ul>
                <li>
                    Asset Creation: Designed and provided various assets,
                    including icons and images, to support the new onboarding
                    process and enhance visual appeal.
                </li>
                <li>
                    Collaboration: Worked closely with developers, UX
                    researchers, and other stakeholders to ensure the new
                    onboarding process was technically feasible and aligned with
                    user needs and business goals.
                </li>
                <li>
                    Wire-framing: Developed detailed wireframes to map out the
                    new onboarding flow, ensuring that each step was logical and
                    user-friendly.
                </li>
                <li>
                    Documentation: Documented the design process, decisions, and
                    outcomes to create a comprehensive case study that
                    highlights the impact of the redesign on user experience and
                    engagement.
                </li>
                <li>
                    Final Design: Crafted the final design for the onboarding
                    screens, focusing on clarity, aesthetics, and consistency
                    with other Autodesk products.
                </li>
            </ul>
        </Row48>

        <Row title="Key Features for Design Improvement">
            <div>
                <div className="row py-xxl px-xl gx-xl px-lg-xxl gx-lg-xxl gy-l">
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Accessibility />
                            </CardTitle>
                            <CardTitle>
                                Improve Accessibility to Learning Contents
                            </CardTitle>
                            Ensure that new users can easily find and access
                            tutorials, documentation, and other learning
                            resources from the onboarding screens.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Autodesk />
                            </CardTitle>
                            <CardTitle>
                                Consistency Across Autodesk Products
                            </CardTitle>
                            Align the design of the onboarding screens with the
                            visual and functional standards of other Autodesk
                            products for a seamless user experience.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Enhancement />
                            </CardTitle>
                            <CardTitle>User-Friendly Interface</CardTitle>
                            Simplify the onboarding process to reduce cognitive
                            load and make the initial steps straightforward and
                            engaging.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Userfriendly />
                            </CardTitle>
                            <CardTitle>Enhanced User Engagement</CardTitle>
                            Increase user engagement and retention by providing
                            a smooth and supportive introduction to Alias.
                        </Card>
                    </Column6>
                </div>
            </div>
        </Row>

        <Row title="Current Status & User Flow">
            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={CurrentStatus}
                    id="alias-final-1"
                    x={109}
                    y={50}
                />
                <NoFrame src={UserFlow} />
            </div>
        </Row>

        <Row title="User's Pain Points">
            <div>
                <div className="row py-xxl px-xl gx-xl gy-l">
                    <Column4>
                        <Card title="Difficult Navigation">
                            New users found it challenging to locate and access
                            learning materials and tutorials.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Inconsistent Design">
                            The onboarding screens of Alias differed
                            significantly from other Autodesk products, causing
                            confusion and a disjointed user experience.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Lack of Guidance">
                            There was insufficient guidance for new users,
                            resulting in a steep learning curve and lower
                            engagement rates.
                        </Card>
                    </Column4>
                </div>
            </div>
        </Row>

        <Row title="New User Flow">
            <NoFrame src={NewUserFlow} />
        </Row>

        <Row48 title="Conceptualization">
            The conceptualization phase began with creating the information
            architecture and low-fidelity concepts for the primary use cases.
            This step involved:
            <br />
            <br />
            <ul>
                <li>
                    Information Architecture: Outlining the structure and
                    organization of content to ensure logical and intuitive
                    navigation.
                </li>
                <li>
                    Low-Fidelity Concepts: Developing initial sketches and
                    wireframes to visualize the onboarding flow and identify key
                    interactions.
                </li>
                <li>
                    After receiving approval from the UX manager and
                    stakeholders on these initial concepts, we proceeded to
                    build low-fidelity mockups. These mockups provided a more
                    detailed view of the design and allowed for further
                    iteration and refinement.
                </li>
            </ul>
        </Row48>

        <Row>
            <div className="px-l px-md-xxxl">
                <Carousel
                    srcs={[
                        Carousel11,
                        Carousel12,
                        Carousel13,
                        Carousel14,
                        Carousel15,
                        Carousel16,
                        Carousel17,
                    ]}
                />
            </div>
        </Row>

        <Row title="Key Features">
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${KeyFeatureVideo1}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <Column6 className="py-s">
                <div className="row gy-m mt-m">
                    <Title title="Easy Access to the Tutorial Site" />
                    <div>
                        Users can now easily find and navigate to the tutorial
                        site directly from the onboarding screens, reducing the
                        time and effort required to locate essential learning
                        resources.
                    </div>
                </div>
            </Column6>
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${KeyFeatureVideo2}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <Column6 className="py-s">
                <div className="row gy-m mt-m">
                    <Title title="Easy Access to Essential Skills with Localization" />
                    <div>
                        The new onboarding process includes links to essential
                        skills tutorials, with localized content to cater to
                        users from different countries, enhancing the learning
                        experience for a global audience.
                    </div>
                </div>
            </Column6>
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${KeyFeatureVideo3}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <Column6 className="py-s">
                <div className="row gy-m mt-m">
                    <Title title="Enhanced Search Feature" />
                    <div>
                        A search feature was integrated into the onboarding
                        screens, allowing users to quickly find specific
                        tutorials, documentation, and other resources.
                    </div>
                </div>
            </Column6>
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${KeyFeatureVideo4}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <Column6 className="py-s">
                <div className="row gy-m mt-m">
                    <Title title="Flexible (Responsive) Screens" />
                    <div>
                        The onboarding screens were designed to be responsive.
                        Users can access to the onboarding screen while users
                        work within the workspace. The new onboarding screen
                        takes less screen space, allowing users to continue
                        their work without significant disruption.
                    </div>
                </div>
            </Column6>
        </Row>

        <Row title="New Onboarding Screen">
            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={NewOnboarding}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </div>
        </Row>

        <Row48 title="Reflection">
            <b>Limitations</b>
            <br />
            As a designer, it is pretty common that we don’t have enough time to
            include users input to the design. Due to time constraints, we were
            unable to conduct as much user research and testing as desired. This
            limited the amount of user feedback could have impacted the accuracy
            of our understanding of user needs and preferences. We had user
            interview at AIF for brief feedback, but some design decisions had
            to be made based on assumptions rather than solid data, which may
            have affected the overall effectiveness of the onboarding redesign.
            I wish that future efforts should prioritize comprehensive user
            research and testing to ensure that the design fully addresses user
            requirements and improves their experience.
            <br />
            <br />
            <b>Future Improvements</b>
            <br />
            Continuous user feedback will be a key to further refine the
            onboarding process. Future updates may focus on personalized
            onboarding experiences and incorporating more interactive elements
            to engage users.
        </Row48>

        <PageEnd />
    </div>
);
