import bcrypt from 'bcryptjs-react';

const hashPasscode = (
    passcode, // eslint-disable-line no-unused-vars
) =>
    bcrypt.hash(passcode, 10).then((hashed) => {
        console.log(hashed);
    });

const hashedPasscode =
    '$2a$10$qxscwKsdfBas/4Bx5aE9ruTyh2a2NV88MHLu4tJE6EUphEFQ4LoxW';

export const checkPasscode = async (form) => {
    let passcode = form.passcode.value;
    return bcrypt.compare(passcode, hashedPasscode);
};
