import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export const HorizontallyScrollableFrame = ({
    src,
    size = '250',
    scale = 1.7,
    alt = '',
}) => {
    // Web browser client window size; using useWindowSize custom hook
    const windowSize = useWindowSize();

    const frame_ref = useRef(null);
    const background_img_ref = useRef(null);
    const main_img_ref = useRef(null);
    const background_img_container_ref = useRef(null);
    const main_img_container_ref = useRef(null);
    const screener_ref = useRef(null);

    const [padding, setPadding] = useState(0);
    const [scrollLeftOffset, setScrollLeftOfset] = useState(null);

    // Update image on scroll
    useEffect(() => {
        main_img_container_ref.current.scrollLeft = scrollLeftOffset * scale;
    }, [scrollLeftOffset, windowSize, padding]); // eslint-disable-line react-hooks/exhaustive-deps

    // Trigger first render upon mount
    useLayoutEffect(() => {
        if (!background_img_ref || !background_img_container_ref) return;
        background_img_ref.current.onload = () => {
            setScrollLeftOfset(background_img_container_ref.current.scrollLeft);
        };
    }, [windowSize, padding]);

    // Initialize frames
    useLayoutEffect(() => {
        if (!frame_ref || !background_img_container_ref) return;

        let frame_width = background_img_container_ref.current.clientWidth;

        // Initialize wrapping frame
        frame_ref.current.style.width = `${frame_width}px`;
        frame_ref.current.style.height = `${background_img_container_ref.current.clientHeight + 12}px`; // Add extra 6 * 2 height for scrollbar

        // Initialize x padding for src image
        background_img_container_ref.current.style.padding = `${80 * scale}px ${padding}px`;

        // Initialize screener
        screener_ref.current.firstChild.style.width = `${(frame_width - 2 * padding) * scale}px`;
    }, [windowSize, padding]); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        let width = windowSize[0];
        if (width > 1200) setPadding(350);
        else if (width > 992) setPadding(320);
        else if (width > 768) setPadding(230);
        else if (width > 576) setPadding(150);
        else if (width > 450) setPadding(120);
        else if (width > 350) setPadding(120 / scale ** 0.5);
        else setPadding(80 / scale ** 0.5);
    }, [windowSize]); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        if (!background_img_container_ref) return;
        const onWheel = (e) => {
            e.preventDefault();
            background_img_container_ref.current.scrollLeft += 2 * e.deltaY;
        };

        background_img_container_ref.current.addEventListener('wheel', onWheel);
        return () =>
            background_img_container_ref.current.removeEventListener(
                'wheel',
                onWheel,
            );
    }, []);

    return (
        <>
            {/* Outermost frame */}
            <div
                ref={frame_ref}
                className="hide-scrollbar w-100 position-relative d-flex align-items-center py-xxl justify-content-center overflow-hidden">
                <div
                    ref={background_img_container_ref}
                    className="position-absolute w-100 edge-blur"
                    onScroll={() =>
                        setScrollLeftOfset(
                            background_img_container_ref.current.scrollLeft,
                        )
                    }
                    style={{ scrollBehavior: 'smooth' }}>
                    {/* Original image to be laid in the background */}
                    <img
                        className="pe-none rounded-3"
                        ref={background_img_ref}
                        src={src}
                        height={size}
                        alt=""
                    />
                </div>

                {/* Screener */}
                <div
                    ref={screener_ref}
                    className="position-absolute top-50 start-50 translate-middle pe-none overflow-hidden"
                    style={{
                        width: `${100 * scale}%`,
                        height: `${100 * scale}%`,
                    }}>
                    <div
                        ref={main_img_container_ref}
                        className="horizontal-scrollable-frame position-absolute top-50 start-50 translate-middle overflow-hidden rounded-3">
                        <img
                            ref={main_img_ref}
                            src={src}
                            height={size * scale}
                            alt={alt}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const Carousel = ({ srcs = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const next = () => {
        if (currentIndex === srcs.length - 1) setCurrentIndex(0);
        else setCurrentIndex(currentIndex + 1);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            next();
        }, 3000);
        return () => clearInterval(interval);
    });

    return (
        <div className="position-relative">
            <div className="d-flex overflow-x-hidden">
                {srcs.map((src, index) => (
                    <div
                        key={index}
                        className="w-100 flex-shrink-0"
                        style={{
                            transform: `translate(-${currentIndex * 100}%)`,
                            opacity: currentIndex == index ? 1 : 0.3,
                            transition:
                                'transform .8s ease-in-out, opacity .8s ease-in-out',
                        }}>
                        <img src={src} className="w-100" alt="" />
                    </div>
                ))}
            </div>
            <div className="position-absolute start-50 translate-middle d-flex flex-row gap-xs pb-xxl">
                {srcs.map((src, index) => (
                    <div
                        key={index}
                        className={`rounded-circle p-xxs ${currentIndex === index ? 'bg-gray06' : 'bg-gray03'}`}
                        onClick={() => setCurrentIndex(index)}
                        role="button"
                    />
                ))}
            </div>
        </div>
    );
};

export const StaticFrame = ({ src, alt = '' }) => {
    const ref = useRef(null);
    const [animated, setAnimated] = useState(false);

    useLayoutEffect(() => {
        const onScroll = () => {
            const triggerPosition = ref.current.offsetTop + 50;
            const scrollPosition = window.scrollY + window.innerHeight;

            if (triggerPosition < scrollPosition) setAnimated(true);
            else setAnimated(false);
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div
            ref={ref}
            className={`static-frame rounded-3 bg-white p-s ${animated ? 'animate' : ''}`}>
            <div className="position-relative">
                <img className="mw-100 mh-100" src={src} alt={alt} />
                <div className="screen bg-white position-absolute w-100 bottom-0" />
            </div>
        </div>
    );
};

export const NoFrame = ({ src, alt = '' }) => (
    <img className="mw-100 mh-100" src={src} alt={alt} />
);

export const LaptopFrame = ({ src, x, y, id }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820 550" fill="none">
        <g clipPath="url(#clip0_923_2765)">
            <g filter="url(#filter0_dddd_923_2765)">
                <path
                    d="M103.127 14H716.872C726.875 14 735 22.1426 735 32.1667V450H85V32.1667C85 22.1426 93.125 14 103.127 14Z"
                    fill="white"
                />
                <path
                    d="M11 450V466.421L40.8699 472.225C46.9424 473.406 53.1188 474 59.3147 474H760.685C766.881 474 773.057 473.406 779.13 472.225L809 466.421V450H11Z"
                    fill="white"
                />
            </g>
        </g>
        <path d="M711 38H109V450H711V38Z" fill="#E3E3E3" />
        <image
            x={x}
            y={y}
            width="602"
            height="412"
            xlinkHref={src}
            clipPath={`url(#1${id})`}
        />
        <defs>
            <filter
                id="filter0_dddd_923_2765"
                x="-23"
                y="7"
                width="866"
                height="586"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="5" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.184314 0 0 0 0 0.172549 0 0 0 0 0.247059 0 0 0 0.05 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_923_2765"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="21" />
                <feGaussianBlur stdDeviation="10.5" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.184314 0 0 0 0 0.172549 0 0 0 0 0.247059 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_923_2765"
                    result="effect2_dropShadow_923_2765"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="48" />
                <feGaussianBlur stdDeviation="14.5" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.184314 0 0 0 0 0.172549 0 0 0 0 0.247059 0 0 0 0.03 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_923_2765"
                    result="effect3_dropShadow_923_2765"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="85" />
                <feGaussianBlur stdDeviation="17" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.184314 0 0 0 0 0.172549 0 0 0 0 0.247059 0 0 0 0.01 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect3_dropShadow_923_2765"
                    result="effect4_dropShadow_923_2765"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect4_dropShadow_923_2765"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_923_2765">
                <rect width="820" height="550" />
            </clipPath>
            <clipPath id={id}>
                <path d="M711 38H109V450H711V38Z" />
            </clipPath>
        </defs>
    </svg>
);
