import { Tag, ProjectTitle, ProjectBanner } from './Components';
import { NoFrame } from './Frames';
import { _VariousContents, _Behaviors, _VisualClarity } from './Icons';
import PanelBanner from './img/panel/banner-panel.png';
import PanelExploration1 from './img/panel/exploration01.png';
import PanelExploration2 from './img/panel/exploration02.png';
import PanelExploration3 from './img/panel/exploration03.png';
import PanelExploration4 from './img/panel/exploration04.png';
import PanelExploration5 from './img/panel/exploration05.png';
import PanelExploration6 from './img/panel/exploration06.png';
import PanelFinalDeisgn1 from './img/panel/panel-finaldesign01.png';
import PanelFinalDeisgn2 from './img/panel/panel-finaldesign02.png';
import PanelJourney from './img/panel/panel-journey.png';
import PanelPersona from './img/panel/panel-persona.png';
import PanelProcess from './img/panel/panel-process01.png';
import PanelResearch1 from './img/panel/panel-research01.png';
import PanelResearch2 from './img/panel/panel-research02.png';
import { ReactComponent as Accessibility } from './img/svg/Accessibility.svg';
import {
    Row48,
    Column6,
    Row,
    PageEnd,
    Card,
    Column4,
    CardTitle,
} from './Layout';
import PanelVideo3 from './video/panel/behaviors.mov';
import PanelVideo1 from './video/panel/collapse.mp4';
import PanelVideo2 from './video/panel/expandcollapse.mp4';

export const Panel = () => (
    <div className="row gy-xxl">
        <ProjectBanner src={PanelBanner} alt="Panel" />

        <ProjectTitle title="Panel for Multi-brand Design system">
            <div className="row gx-xs gy-xs">
                <div className="col-auto">
                    <Tag text="UI/UX" />
                </div>
                <div className="col-auto">
                    <Tag text="Visual Design" />
                </div>
                <div className="col-auto">
                    <Tag text="Interaction Design" />
                </div>
                <div className="col-auto">
                    <Tag text="Prototyping" />
                </div>
                <div className="col-auto">
                    <Tag text="User Research" />
                </div>
            </div>
        </ProjectTitle>

        <Row48 title="Overview">
            Weave is the Autodesk product design system that provides the tools
            that teams need to create cohesive digital experiences. Weave is a
            multi-brand design system which covers various use-cases from
            complex, information-dense applications to simple, minimalist
            applications. <br />
            <br />
            Each designer is fully responsible for every aspect of one component
            that they are in charge of: user research, visual design,
            interaction, layouts, prototype, and documentation. Panel is one of
            the most complex component due to its unique usages across all
            Autodesk desktop products.
        </Row48>

        <Row48 title="Duration">May 2019 - May 2020</Row48>
        <Row48 title="Role">
            My mission is to develop a comprehensive set of design, content,
            interaction patterns, and re-usable components that can be adaptable
            for all Autodesk products. Each component must have clear
            accessibility guidelines, ensuring consistency across products.
            <br />
            <br />I was responsible for creating multiple components, but I've
            decided to highlight the “panel” component as the centerpiece due to
            a few reasons:
            <ul>
                <li>
                    Complexity: The panel represents one of the most complex
                    design system components due to its hierarchical structure
                    and dynamic behavior.
                </li>
                <li>
                    Impact on User: Most of tools and controls at Autodesk
                    desktop product are contained in panels, and it impacts the
                    overall user experience directly.
                </li>
                <li>
                    Comprehensive skills: Panel design required a combination of
                    skills in user research, interaction design, visual design,
                    prototyping, testing, and iteration.
                </li>
            </ul>
        </Row48>

        <Row48 title="Challenges">
            <ul>
                <li>
                    Complexity: As more exceptions and unique cases came to
                    consider, the component became complex and often created
                    confusion for consumers.{' '}
                </li>
                <li>
                    Consistency across products/platforms: Designing components
                    that work seamlessly across different products were pretty
                    challenging since each product had their unique patterns
                    with their own guidelines with technical constraints,
                    requiring careful adaptation.
                </li>
                <li>
                    Inclusive design: I needed to consider users with various
                    needs and abilities including keyboard navigation or screen
                    compatibility. It was challenging to create components that
                    meet accessibility standards for all products.
                </li>
                <li>
                    Adoption: Panel was one of essential components for most of
                    desktop products. Introducing new design can be met with
                    resistance from the product team. I needed to provide clear
                    resources and support to help them understand the benefits
                    of adopting new design system.{' '}
                </li>
            </ul>
        </Row48>

        <Row title="Design Process">
            <NoFrame src={PanelProcess} />
        </Row>

        <Row48 title="Audit/User Research">
            In order to gain comprehensive insights about the panel component, I
            facilitated a collaborative sessions with designers from both
            product teams and design system team. Through in-person meetings,
            virtual workshops through Mural, or Zoom calls, I identified current
            status, existing challenges, and their expectations for the
            redesigned panel interface.
        </Row48>

        <div className="px-xxl">
            <NoFrame src={PanelResearch1} />
        </div>

        <div className="px-xxl">
            <NoFrame src={PanelResearch2} />
        </div>

        <Row title="User Persona">
            <div className="col-6 col-md-4">
                <div className="d-flex align-items-center justify-content-center py-l">
                    <NoFrame src={PanelPersona} />
                </div>
            </div>
            <div className="col-6 col-md-8">
                <div className="py-l">
                    <h3 className="mb-xl fw-bold">Meet Margaret!</h3>
                    We've segmented our audience and chosen to prioritize a
                    single persona. <br />
                    She represents:
                    <ul>
                        <li>Majority of our audience</li>
                        <li>
                            High influence on our design system by constribution
                        </li>
                    </ul>
                </div>
            </div>
            <Column6>
                <div className="row gy-xl">
                    <div>
                        <h3 className="mb-xl fw-bold">Margaret Chen</h3>
                        <ul>
                            <li>Age: 45 years old</li>
                            <li>Occupation: Principle UX Designer at Revit</li>
                            <li>Location: Portland, Oregon</li>
                            <li>
                                Experience: 20 years in design, 12 years at
                                Autodesk
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="fw-bold">Responsibilities</h4>
                        <ul>
                            <li>
                                Overseeing the design of new features and
                                enhancement for Revit
                            </li>
                            <li>
                                Collaborating closely with architects, engineers
                            </li>
                            <li>
                                Ensuring the design consistency across Revit and
                                integration with the Autodesk design system
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="fw-bold">Design Tools</h4>
                        <ul>
                            <li>Sketch app</li>
                            <li>Adobe Photoshop</li>
                            <li>Figma (beginner)</li>
                        </ul>
                    </div>
                </div>
            </Column6>

            <Column6>
                <div className="row gy-xl">
                    <div>
                        <h4 className="fw-bold">Goals</h4>
                        <ul>
                            <li>
                                To enhance usability and accessibility of Revit,
                                making it easier for professionals to adopt
                            </li>
                            <li>
                                To ensure that Revit’s design aligns with
                                Autodesk overall design system, promoting
                                cohesive user experience across products
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="fw-bold">Challenges</h4>
                        <ul>
                            <li>
                                Navigating the complexity of integrating Revit’s
                                unique features and workflows with the design
                                system
                            </li>
                            <li>
                                Addressing the diverse needs of Revit’s use base
                            </li>
                            <li>
                                Managing and incorporating feedback from a wide
                                range of users, often with conflicting
                                requirements
                            </li>
                            <li>
                                Keeping up with rapid technological advancements
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="fw-bold">
                            Project Management/Collaboration Tools
                        </h4>
                        <ul>
                            <li>JIRA</li>
                            <li>Mural</li>
                            <li>Slack</li>
                        </ul>
                    </div>
                </div>
            </Column6>
        </Row>

        <Row title="User Journey">
            <NoFrame src={PanelJourney} />
        </Row>

        <Row title="Pain Points">
            <div>
                <div className="row py-xxl px-xl gx-xl gy-l">
                    <Column4>
                        <Card title="Desire for More Examples">
                            Product teams have expressed a desire to see more
                            examples of panel designs with various contents to
                            use as guidance. The lack of visual examples or case
                            studies showcasing different panel implementations
                            creates uncertainty in adapting designs.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Inconsistency Across Brands">
                            Different products under the same umbrella may have
                            their own unique visual styles and guidelines,
                            leading to inconsistencies across brands. This
                            fragmentation can confuse users and dilute brand
                            identity.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Complexity">
                            Panels in the design system have too many optional
                            elements, overwhelming and confusing users. This
                            complexity makes it difficult to understand the
                            purpose of the panel and how to navigate its
                            features effectively.
                        </Card>
                    </Column4>
                </div>
            </div>
        </Row>

        <Row title="Key Features for Design Improvement">
            <div>
                <div className="row py-xxl px-xl gx-xl px-lg-xxl gx-lg-xxl gy-l">
                    <Column6>
                        <Card>
                            <CardTitle>
                                <_VisualClarity />
                            </CardTitle>
                            <CardTitle>Visual Clarity</CardTitle>
                            Panels commonly act as containers that enclose
                            specific contents. Some of clear visual dividers
                            such as shadows or borders may be needed to separate
                            panels from surround elements.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <_VariousContents />
                            </CardTitle>
                            <CardTitle>Various Contents</CardTitle>
                            Panels obtains numerous types of contents such as
                            text, images, forms, tables, and controls. The
                            layouts within panels should consider clear
                            hierarchy of contents.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Accessibility />
                            </CardTitle>
                            <CardTitle>Accessibility</CardTitle>
                            Panels should be designed with accessibility,
                            ensuring that understandable for all users including
                            people with disabilities. This may involve color
                            contrast, keyboard navigation, or screen
                            compatibility.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <_Behaviors />
                            </CardTitle>
                            <CardTitle>Behaviors</CardTitle>
                            There were some of key behaviors to consider to
                            design panels for multi-products. Panels had many
                            interactive behaviors such as close/open,
                            expand/collapse, and docking/undocking.
                        </Card>
                    </Column6>
                </div>
            </div>
        </Row>

        <Row title="Design Exploration">
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration1} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration2} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration3} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration4} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration5} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={PanelExploration6} />
            </div>
        </Row>

        <Row title="Prototyping">
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${PanelVideo1}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <Column6>
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${PanelVideo2}#t=0.001`}
                    type="video/mp4"
                />
            </Column6>
            <div className="col-12">
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${PanelVideo3}#t=0.001`}
                    type="video/mp4"
                />
            </div>
        </Row>

        <Row title="Final Design">
            <NoFrame src={PanelFinalDeisgn1} />
            <NoFrame src={PanelFinalDeisgn2} />
        </Row>

        <Row48 title="Outcome">
            The panel component is now widely used across various Autodesk
            products including AutoCAD, Inventor, Revit, and Alias, etc. It
            brought significant impacts and benefits for users and the Autodesk
            ecosystem as well.
            <br />
            <br />A standardized panel component fosters collaboration among
            users who work across different Autodesk products. Familiarity with
            the panel interface enables smoother transition between products and
            promotes knowledge sharing. By implementing a standardized panel,
            users across different Autodesk products experience consistency in
            design patterns and work flows.
        </Row48>

        <Row48 title="Learnings and Reflection">
            Even we launched the desktop design system successfully, it still
            needs some additional functions and patterns as more products
            started to adopt the components. Building multi-brand design system
            was really challenging in terms of many aspects. Each component took
            much longer time because of its complexity and different
            expectations from different teams. We also accepted that the
            components needed to be flexible, so components can be adapted to
            each product's unique pattern by keeping atomic level of elements
            strict, but allowing minor modifications depending on the product
            team. I learned that the design system is not static; it keeps
            evolving over time to accommodate changing needs and requirements.
            <br />
            <br />
            With all challenges, I had a pleasant time learning about strategic
            process including collaboration, user-testing, real-world
            usage-data, and iteration.
        </Row48>

        <PageEnd />
    </div>
);
