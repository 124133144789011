import { createContext, useContext, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

const ModalContext = createContext(null);
export const useModalContext = () => {
    return useContext(ModalContext);
};
export const ModalLayout = () => {
    const [content, setContent] = useState('');
    const on = (content) => setContent(content);
    const off = () => setContent(null);

    return (
        <ModalContext.Provider value={{ content, on, off }}>
            <Outlet />
        </ModalContext.Provider>
    );
};

export const Modal = ({ children }) => {
    const [animated, animate] = useState(false);
    useEffect(() => animate(true), []);

    return (
        <>
            <div
                className={`modal d-flex align-items-center fade
                ${animated ? 'show' : ''}`}
                tabIndex="-1">
                {children}
            </div>
            <div
                className={`modal-backdrop fade ${animated ? 'show' : ''}`}></div>
        </>
    );
};
