import { Tag, ProjectTitle, KeyFinding, ProjectBanner } from './Components';
import { Carousel, LaptopFrame, NoFrame } from './Frames';
import { _Company, _Users } from './Icons';
import AliasAudit from './img/alias/Alias-Audit.png';
import AliasKeyFindingsCameraView from './img/alias/Alias-cameraview.png';
import AliasCurrentStatus1 from './img/alias/Alias-currentStatus1.png';
import AliasCurrentStatus2 from './img/alias/Alias-currentStatus2.png';
import AliasDesignExploration1 from './img/alias/Alias-designexploration-1st.png';
import AliasDesignExploration2 from './img/alias/Alias-designexploration02.png';
import AliasDesignExploration3 from './img/alias/Alias-designexploration03.png';
import AliasDesignExploration4 from './img/alias/Alias-designexploration04.png';
import AliasKeyFindingsSymbols from './img/alias/Alias-inconsistentsymbols.png';
import AliasKeyFindingsLegacy from './img/alias/Alias-legacy.png';
import AliasProcess from './img/alias/Alias-process.png';
import AliasKeyFindingsReadability from './img/alias/Alias-readability.jpg';
import AliasKeyFindingsMetaphor from './img/alias/Alias-universalmetaphors.png';
import AliasKeyFindingsVisualLanguages from './img/alias/Alias-visuallanguages.png';
import AliasBanner from './img/alias/banner-alias.png';
import AliasFinal1 from './img/alias/final01.png';
import AliasFinal2 from './img/alias/final02.png';
import AliasIconset01 from './img/alias/iconset01.png';
import AliasIconset02 from './img/alias/iconset02.png';
import AliasIconset03 from './img/alias/iconset03.png';
import AliasIconset04 from './img/alias/iconset04.png';
import AliasIconset05 from './img/alias/iconset05.png';
import AliasIconset06 from './img/alias/iconset06.png';
import AliasIconset07 from './img/alias/iconset07.png';
import AliasIconset08 from './img/alias/iconset08.png';
import { Row48, Column6, Row, PageEnd, Card, CardTitle } from './Layout';

export const Alias = () => (
    <div className="row gy-xxl">
        <ProjectBanner src={AliasBanner} alt="Alias" />

        <ProjectTitle title="Alias Icon Modernization">
            <div className="row gx-xs gy-xs">
                <div className="col-auto">
                    <Tag text="Iconography" />
                </div>
                <div className="col-auto">
                    <Tag text="Visual Design" />
                </div>
                <div className="col-auto">
                    <Tag text="User Research" />
                </div>
            </div>
        </ProjectTitle>
        <Row48 title="Overview">
            Autodesk Alias is a design software mainly used for automotive 3D
            modeling. In Alias 2024, we have a major update for complete UI
            change with new iconography. In my team, we are responsible for new
            user interface which brings cohesive experience and visuals with
            other Autodesk product yet it still has Alias DNA.
        </Row48>

        <Row48 title="Duration">
            Project initiation: November 2021 <br />
            Final implementation: Apr 2023
        </Row48>
        <Row48 title="Objective">
            My mission was to enhance visual clarity across product interface,
            improving user comprehension and efficiency by removing all
            unnecessary visual details, noise and inconsistency that hinders
            readability. Refreshing Alias icons will also increase cohesion and
            consistency within Alias, and other Autodesk products.
        </Row48>

        <Row48 title="Challenges">
            <ul>
                <li>No design guideline exists.</li>
                <li>
                    Huge gap of styles in between other Autodesk icon design and
                    current Alias icon design.
                </li>
                <li>
                    Users have been used old icons for decades of period, and
                    afraid of changing.
                </li>
                <li>
                    Technical limitation: Certain features are not available.
                </li>
            </ul>
        </Row48>

        <Row title="Design Process">
            <NoFrame src={AliasProcess} />
        </Row>

        <Row title="Current Status">
            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={AliasCurrentStatus1}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </div>
            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={AliasCurrentStatus2}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </div>
        </Row>

        <Row title="Audit">
            <Column6>
                <NoFrame src={AliasAudit} />
            </Column6>
            <Column6 className="py-s">
                <ul>
                    <li>
                        I compiled a list of all icons currently in use, then
                        started to evaluate the visual consistency of the icons
                        in terms of style, size, color, and level of detail.
                    </li>
                    <li>
                        I checked for accessibility including contrast ratio
                        between icons and user interface to ensure that icons
                        are clearly perceived.
                    </li>
                    <li>
                        A lot of icons are too complex and looked similar,
                        confusing users to identify its function.
                    </li>
                    <li>
                        Some of general icons such as save, open, or copy were
                        hard to recognize. They can be replaced by universal
                        metaphors.
                    </li>
                    <li>
                        I had a review with internal users and customers to
                        identify any issues from users' point of view.
                    </li>
                </ul>
            </Column6>
        </Row>

        <Row title="Key Findings">
            <div>
                <div className="row py-xxl px-xl gx-xl px-lg-xxl gx-lg-xxl gy-l">
                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={AliasKeyFindingsReadability} />
                            </CardTitle>
                            <CardTitle>
                                Readability on Light and Dark Background
                            </CardTitle>

                            <ul>
                                <li>
                                    Icons designed for light themes often
                                    appeared washed out or invisible on dark
                                    themes.
                                </li>
                                <li>
                                    Lack of contrast adjustments caused icons to
                                    blend into the background, reducing
                                    visibility.
                                </li>
                                <li>
                                    Icons used the same color palette for both
                                    themes, which was not optimized for
                                    readability across different backgrounds.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={AliasKeyFindingsCameraView} />
                            </CardTitle>
                            <CardTitle>Inconsistent Camera Views</CardTitle>

                            <ul>
                                <li>
                                    Icons were created from varying camera
                                    angles, leading to a lack of uniformity.
                                </li>
                                <li>
                                    Some icons featured top-down views, while
                                    others had oblique or side views, creating
                                    visual dissonance.
                                </li>
                                <li>
                                    Users found it challenging to quickly
                                    recognize and differentiate between icons
                                    due to inconsistent perspectives.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={AliasKeyFindingsSymbols} />
                            </CardTitle>
                            <CardTitle>
                                Inconsistent Use of Color Identity
                            </CardTitle>

                            <ul>
                                <li>
                                    Icons utilized a wide range of colors
                                    without a clear, standardized palette.
                                </li>
                                <li>
                                    Similar functions were represented by
                                    different colors, creating confusion.
                                </li>
                                <li>
                                    Users struggled to associate specific colors
                                    with specific actions or categories, leading
                                    to slower navigation.
                                </li>
                                <li>
                                    Historical accumulation of icons led to a
                                    patchwork of color schemes.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame
                                    src={AliasKeyFindingsVisualLanguages}
                                />
                            </CardTitle>
                            <CardTitle>
                                Inconsistent Symbols and Badges
                            </CardTitle>

                            <ul>
                                <li>
                                    Lack of standardization in badge design led
                                    to confusion and misinterpretation.
                                </li>
                                <li>
                                    Users had difficulty understanding the
                                    meaning of certain icons due to varied
                                    symbols and badges.
                                </li>
                                <li>
                                    Visual clutter from diverse badges and
                                    symbols detracted from the overall interface
                                    coherence.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={AliasKeyFindingsLegacy} />
                            </CardTitle>
                            <CardTitle>
                                Differentiating between Legacy and New Tools
                            </CardTitle>

                            <ul>
                                <li>
                                    Both legacy and new tools had their own
                                    icons, causing confusion among users.
                                </li>
                                <li>
                                    Legacy tools were often kept due to user
                                    preference, resulting in duplicate
                                    functionality and icons.
                                </li>
                                <li>
                                    The presence of both legacy and new icons
                                    cluttered the interface, making it less
                                    intuitive.
                                </li>
                                <li>
                                    Lack of clear visual differentiation
                                    strategies for legacy and new tools.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={AliasKeyFindingsMetaphor} />
                            </CardTitle>
                            <CardTitle>Universal Metaphors</CardTitle>

                            <ul>
                                <li>
                                    They help in creating intuitive and easily
                                    recognizable icons, reducing the learning
                                    curve for new users.
                                </li>
                                <li>
                                    Using universal metaphors ensures
                                    consistency across different platforms and
                                    products, enhancing usability.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                </div>
            </div>
        </Row>

        <Row48 title="Design Approach">
            <ul>
                <li>
                    Balancing adherence to Weave(Autodesk Design System) and
                    current customer's familiarity
                </li>
                <li>
                    Compatibility with current UI and iconography to slowly
                    introduce visual change
                </li>
            </ul>
        </Row48>

        <Row48 title="Design Explorations 01">
            <KeyFinding title="First attempt to stay close to Weave guideline(other Autodesk product icons)">
                Reactions
                <ul>
                    <li>
                        Alias has many similar icons and hard to differentiate
                        icons
                    </li>
                    <li>Icons look too flat</li>
                    <li>Shading looks not realistic</li>
                </ul>
            </KeyFinding>
        </Row48>

        <div className="px-xxl mt-0">
            <NoFrame src={AliasDesignExploration1} />
        </div>

        <Row48 title="Design Explorations 02">
            <KeyFinding title="Second attempt to align more with current Alias icons, but we adapted Weave color palette and flat icons (mix&match)">
                What we modified from the first attempt:
                <ul>
                    <li>
                        Explore more on the perspective view, making icons more
                        realistic and not too flat
                    </li>
                    <li>
                        Since there is a technical limitation that we can only
                        implement one icon set for both light and dark theme,
                        find the best contrast ratio that works for both color
                        themes.
                    </li>
                </ul>
                <br />
                Reactions:
                <ul>
                    <li>
                        I like the icons look simple and cleaner, but still
                        recognizable
                    </li>
                    <li>Gray line looks like it is disabled</li>
                    <li>
                        I prefer the blue tint for default object icon, gray
                        tinted objects look too muddy
                    </li>
                </ul>
            </KeyFinding>
        </Row48>

        <div className="px-xxl">
            <NoFrame src={AliasDesignExploration2} />
            <div className="fw-bold">
                Explorations on consistency and familiarity
            </div>
        </div>

        <div className="px-xxl">
            <NoFrame src={AliasDesignExploration3} />
            <div className="fw-bold">Explorations on flat icons</div>
        </div>

        <div className="px-xxl">
            <NoFrame src={AliasDesignExploration4} />
            <div className="fw-bold">
                Explorations on perspective views and color contrast
            </div>
        </div>

        {/* <Row title="Final Icon Set">
            <div className="px-s px-md-0 mt-l">
                <HorizontallyScrollableFrame src={AliasLongLight} />
            </div>
            <div className="px-s px-md-0 mt-l">
                <HorizontallyScrollableFrame src={AliasLongDark} />
            </div>
        </Row> */}

        <Row title="Final Icon Set">
            <div className="px-m px-md-xxl">
                <Carousel
                    srcs={[
                        AliasIconset01,
                        AliasIconset02,
                        AliasIconset03,
                        AliasIconset04,
                        AliasIconset05,
                        AliasIconset06,
                        AliasIconset07,
                        AliasIconset08,
                    ]}
                />
            </div>
        </Row>

        <Row title="Final Design">
            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={AliasFinal1}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </div>

            <div className="px-m px-md-xxl">
                <LaptopFrame
                    src={AliasFinal2}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </div>
        </Row>

        <Row title="Outcome">
            <div>
                <div className="row py-xxl px-xl gx-xl px-lg-xxl gx-lg-xxl gy-l">
                    <Column6>
                        <Card>
                            <CardTitle>
                                <_Users />
                            </CardTitle>
                            <CardTitle>User's Perspective</CardTitle>

                            <ul>
                                <li>
                                    Both dark and light themes are now
                                    supported, improving usability and
                                    readability across different display
                                    settings.
                                </li>
                                <li>
                                    Consistent iconography reduces the learning
                                    curve for new users and increases
                                    productivity for existing users.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <_Company />
                            </CardTitle>
                            <CardTitle>Company's Perspective</CardTitle>

                            <ul>
                                <li>
                                    The redesigned icons now align with the
                                    Autodesk design system, ensuring consistency
                                    across products and enhancing brand
                                    identity.
                                </li>
                                <li>
                                    Achieved silver medal status within the
                                    governance model
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                </div>
            </div>
        </Row>

        <Row48 title="Relection">
            <p>
                From this project, I leaned many valuable lessons. Alias had
                pretty complex icon guideline. It was a challenge to keep the
                balance between the Autodesk icon guideline and Alias' own
                design direction. Due to its unique features, users needs were
                pretty different from the design direction of Autodesk design
                system. <br />
                <br />
                I also had several feedback sessions with end users, allowed me
                to design with user-centric focus. It demonstrated the
                significance of understanding user preferences, behaviors, and
                expectations to create designs that resonate with the target
                audience. <br />
                <br />
                Communication skills were also something that I have improved
                from this project. Learning how to interpret feedback,
                extracting meaningful insights, and communicate design decisions
                demonstrates professional maturity and enhances collaboration
                skills. <br />
                <br />
                In the end, I learned so much about the importance of user
                feedback, flexibility to adapt different needs, and the value of
                maintaining a user-centric design approach.
            </p>
        </Row48>

        <PageEnd />
    </div>
);
