import { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { CheckPasscode, Footer, Navigation, Topbar } from './Components';
import { Modal, useModalContext } from './Modal';

export const ContentsContainer = ({
    children,
    size = '1024',
    className = '',
    style = {},
}) => (
    <div
        className={`container d-flex justify-content-center px-m px-xl-0 ${className}`}
        style={style}>
        <div className="w-100" style={{ maxWidth: size + 'px' }}>
            {children}
        </div>
    </div>
);

export const Title = ({ title = '' }) => (
    <h3 className="fw-bold m-0">{title}</h3>
);

export const Row48 = ({ children, title = '' }) => (
    <div className="col-12 py-s2">
        <div className="row gy-m">
            <div className="col-12 col-md-4">
                <Title title={title} />
            </div>
            <div className="col-12 col-md-8 lh-1600">{children}</div>
        </div>
    </div>
);

export const Row = ({ children, title = '' }) => (
    <div className="col-12 py-s2">
        <div className="row gy-m">
            <div className="col-12">
                <Title title={title} />
            </div>
            <div className="col-12">
                <div className="row gx-xl gy-l">{children}</div>
            </div>
        </div>
    </div>
);

export const Column6 = ({ children, className = '' }) => (
    <div className={`col-12 col-md-6 ${className}`}>{children}</div>
);

export const Column4 = ({ children, className = '' }) => (
    <div className={`col-12 col-lg-4 ${className}`}>{children}</div>
);

export const CardTitle = ({ children }) => (
    <div className="d-flex justify-content-center pb-l fw-bold fs-m text-center">
        {children}
    </div>
);

export const Card = ({ title = '', children }) => (
    <div className="card p-xl h-100">
        {title ? <CardTitle>{title}</CardTitle> : null}
        {children}
    </div>
);

export const PageEnd = ({ className = '' }) => (
    <div className={`mb-xxxl ${className}`} />
);

export const PageLayout = () => {
    const modalContext = useModalContext();
    return (
        <>
            {modalContext.content === 'navigation' ? (
                <Modal>
                    <Navigation />
                </Modal>
            ) : null}
            <Topbar />
            <Outlet />
        </>
    );
};

export const LandingLayout = () => (
    <>
        <Outlet />
        <Footer />
    </>
);

export const ProjectLayout = () => {
    const modalContext = useModalContext();
    const [validated, validate] = useState(false);

    useEffect(() => {
        if (!validated) modalContext.on('checkpasscode');
        else modalContext.off();
        return () => {
            modalContext.off();
        };
    }, [validated]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!validated) {
        return (
            <>
                {modalContext.content === 'checkpasscode' ? (
                    <Modal>
                        <CheckPasscode validate={validate} />
                    </Modal>
                ) : null}
            </>
        );
    } else {
        return (
            <>
                <ContentsContainer>
                    <Outlet />
                </ContentsContainer>
                <Footer />
            </>
        );
    }
};
