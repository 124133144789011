import Snap from 'snapsvg-cjs';

const ANIMATION_JUMP_DURATION = 1500;
const ANIMATION_FALL_DURATION = 700;
const BRIGHTNESS_DARK = 1;
const BRIGHTNESS_BRIGHT = 1.2;

export function jumpLetter(target_group) {
    let group_letter = Snap('g#' + target_group.id);
    if (!group_letter) return;

    let letter = group_letter.select('.letter');
    jump(letter);
    brighten(letter);

    let shadow = group_letter.select('.shadow');
    blur(shadow);
}

export function fallLetter(target_group) {
    let group_letter = Snap('g#' + target_group.id);
    if (!group_letter) return;

    let letter = group_letter.select('.letter');
    fall(letter);
    darken(letter);

    let shadow = group_letter.select('.shadow');
    unblur(shadow);
}

function jump(el) {
    if (!el) return;
    el.animate(
        { transform: 't0,-30' },
        ANIMATION_JUMP_DURATION,
        window.mina.easein,
    );
}

function fall(el) {
    if (!el) return;
    el.animate(
        { transform: 't0,0' },
        ANIMATION_FALL_DURATION,
        window.mina.bounce,
        () => {
            el.animate(
                { transform: 't0,0' },
                ANIMATION_JUMP_DURATION - ANIMATION_FALL_DURATION,
                window.mina.bounce,
            );
        },
    );
}

function blur(el) {
    if (!el) return;
    let f = el.filter(Snap.filter.blur(10, 10));
    let gaussian_filter = f.node.firstChild;

    el.attr({ filter: f });
    Snap.animate(
        0,
        4,
        function (value) {
            gaussian_filter.attributes[0].value = value + ',' + value;
        },
        ANIMATION_JUMP_DURATION,
        window.mina.easein,
    );
}

function unblur(el) {
    if (!el) return;
    let f = el.filter(Snap.filter.blur(10, 10));
    let gaussian_filter = f.node.firstChild;

    el.attr({ filter: f });
    Snap.animate(
        4,
        0,
        function (value) {
            gaussian_filter.attributes[0].value = value + ',' + value;
        },
        ANIMATION_FALL_DURATION,
        window.mina.bounce,
        () => {
            el.animate(
                { transform: 't0,0' },
                ANIMATION_JUMP_DURATION - ANIMATION_FALL_DURATION,
            );
        },
    );
}

function brighten(el) {
    if (!el) return;
    let f = el.filter(Snap.filter.brightness(BRIGHTNESS_BRIGHT));
    let filter = f.node.firstChild;

    el.attr({ filter: f });
    Snap.animate(
        BRIGHTNESS_DARK,
        BRIGHTNESS_BRIGHT,
        function (value) {
            filter.children[0].attributes[1].value = `${value}`;
            filter.children[1].attributes[1].value = `${value}`;
            filter.children[2].attributes[1].value = `${value}`;
        },
        ANIMATION_JUMP_DURATION,
        window.mina.easein,
    );
}

function darken(el) {
    if (!el) return;
    let f = el.filter(Snap.filter.brightness(BRIGHTNESS_DARK));
    let filter = f.node.firstChild;

    el.attr({ filter: f });
    Snap.animate(
        BRIGHTNESS_BRIGHT,
        BRIGHTNESS_DARK,
        function (value) {
            filter.children[0].attributes[1].value = `${value}`;
            filter.children[1].attributes[1].value = `${value}`;
            filter.children[2].attributes[1].value = `${value}`;
        },
        ANIMATION_FALL_DURATION,
        window.mina.bounce,
        () => {
            el.animate(
                { transform: 't0,0' },
                ANIMATION_JUMP_DURATION - ANIMATION_FALL_DURATION,
            );
        },
    );
}

function _breezHair(hair) {
    if (!hair) return;
    let cx = 169.5655;
    let cy = 59.1702;

    hair.animate(
        { transform: 'r-5,' + cx + ',' + cy },
        1000,
        window.mina.ease,
        () => {
            hair.animate(
                { transform: 'r5,' + cx + ',' + cy },
                1500,
                window.mina.ease,
            );
        },
    );
}

export function breezeHair(delay = 0) {
    let next_delay = 2500 + Math.random() * 2 * 1000; // Range [2500, 4500] in ms

    setTimeout(() => {
        _breezHair(Snap('path#hair'));
        breezeHair(next_delay);
    }, delay);
}

function _blinkEye(eye) {
    if (!eye) return;
    let avatar = Snap('#avatar');
    let bbox = eye.getBBox();
    let r = (bbox.y2 - bbox.y) / 2;
    let cx = bbox.cx;
    let cy = bbox.cy;

    let cover = avatar.circle(cx + 0.7, cy - 1.5, r);

    cover.attr({ fill: '#FCDCC8' });
    setTimeout(() => {
        cover.attr({ fill: 'none' });
    }, 180);
}

function _lowerEyebrow(eyebrow) {
    if (!eyebrow) return;

    eyebrow.animate({ transform: 't0, 2' }, 180, () => {
        eyebrow.animate({ transform: 't0, 0' }, 0);
    });
}

export function blinkEye(delay = 0) {
    let next_delay = 5000 + Math.random() * 1000; // Range [2500, 4500] in ms

    setTimeout(() => {
        _blinkEye(Snap('path#eye-left'));
        _blinkEye(Snap('path#eye-right'));
        _lowerEyebrow(Snap('path#eyebrow-left'));
        _lowerEyebrow(Snap('path#eyebrow-right'));
        blinkEye(next_delay);
    }, delay);
}

function _drawLines(lines, pencil, animationArray, index) {
    if (index >= animationArray.length) {
        setTimeout(() => {
            pencil.attr({ opacity: 0 });
        }, 500);
        return;
    }
    let line = lines[index];
    let length = line.getTotalLength();
    let anim = animationArray[index];

    let effect = anim['effect'] || window.mina.easein;
    let reverse = anim['reverse'] ? 1 : -1;

    line.attr({
        stroke: anim['color'] || 'black',
        strokeWidth: anim['width'] || 2,
        strokeDasharray: length,
        strokeDashoffset: length,
    });

    Snap.animate(
        0,
        length,
        function (value) {
            let p;
            if (reverse === -1) {
                p = line.getPointAtLength(value);
            } else {
                p = line.getPointAtLength(length - value);
            }
            pencil.transform(`t${p.x + 1},${p.y - 29}`);
            line.attr({ strokeDashoffset: length + reverse * value });
        },
        anim['duration'] || 200,
        effect,
        () => {
            _drawLines(lines, pencil, animationArray, index + 1);
        },
    );
}

export function drawSignature() {
    let delay = 400;
    let signature = Snap('#signature');
    let letters = signature.selectAll('path');
    let pencil = signature.select('g');

    const speedFactor = 1.5;

    // Remove all drawn signature letters
    letters.attr({ stroke: 'none' });

    // Draw pencil
    pencil.attr({ opacity: 0 });
    pencil.transform('t1,-29');

    let animationArray = [
        { duration: 300 / speedFactor, reverse: true }, // J
        { duration: 400 / speedFactor }, // I
        { duration: 200 / speedFactor, reverse: true }, // E1
        { duration: 300 / speedFactor }, // E2
        { duration: 300 / speedFactor }, // E3
        { duration: 500 / speedFactor, reverse: true }, // U
        { duration: 200 / speedFactor, reverse: true }, // N1
        { duration: 500 / speedFactor }, // N2
        { duration: 300 / speedFactor, reverse: true }, // J
        { duration: 300 / speedFactor, reverse: true }, // A1
        { duration: 300 / speedFactor }, // A2
        { duration: 200 / speedFactor }, // A3
        { duration: 200 / speedFactor, reverse: true }, // Y1
        { duration: 400 / speedFactor }, // Y2
        { duration: 200 / speedFactor }, // K1
        { duration: 200 / speedFactor, reverse: true }, // K2
        { duration: 300 / speedFactor }, // K3
        { duration: 400 / speedFactor }, // I
        { duration: 200 / speedFactor, reverse: true }, // M1
        { duration: 500 / speedFactor }, // M2
    ];

    setTimeout(() => {
        _drawLines(letters, pencil, animationArray, 0);
    }, delay);
}

// const AnimationContext = createContext(null);
// const useAnimationContext = () => {
//     return useContext(AnimationContext);
// };
// export const AnimationOnPageScroll = ({children, updateFrame}) => {
//     const [numFrames, setNumFrames] = useState([]);
//     const addNumFrame = (numFrame) => setNumFrames([...numFrames, numFrame]);

//     const onScroll = () => {
//         const scrollTop = window.scrollY || document.documentElement.scrollTop;
//         const maxScrollTop = window.scrollHeight || document.documentElement.scrollHeight- window.innerHeight;
//         const scrollFraction = scrollTop / maxScrollTop;

//         const totalNumFrames = numFrames.reduce((a,b) => a+b, 0);
//         const i = Math.floor(scrollFraction * (totalNumFrames -1));

//         console.log(updateFrame)

//         requestAnimationFrame(() => updateFrame(i));
//     }

//     useLayoutEffect(() => {
//         window.addEventListener("scroll", onScroll);
//         return () => window.removeEventListener("scroll", onScroll);
//     }, [])

//     return (
//         <AnimationContext.Provider value={{numFrames, addNumFrame}}>
//             {children}
//         </AnimationContext.Provider>
//     )
// };

// export const TextTypingOnScroll = forwardRef(({text}, ref) => {
//     const animationCtx = useAnimationContext();
//     const totalFrameCounts = text.length;

//     useLayoutEffect(() => {
//         animationCtx.addNumFrame(totalFrameCounts);
//     }, [])

//     return (
//         <div ref={ref} className="w-100" style={{height: `${totalFrameCounts * 1}%`}}>
//             {text}
//         </div>
//     )
// });
