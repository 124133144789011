import { Tag, ProjectTitle, KeyFinding, ProjectBanner } from './Components';
import { NoFrame } from './Frames';
import TabBanner from './img/tab/banner-tab.png';
import TabExploration1 from './img/tab/tab-exploration1.png';
import TabExploration2 from './img/tab/tab-exploration2.png';
import TabExploration3 from './img/tab/tab-exploration3.png';
import TabExploration4 from './img/tab/tab-exploration4.png';
import TabFinalDesign from './img/tab/tab-final.png';
import TabProcess from './img/tab/tab-process01.png';
import TabResearch1 from './img/tab/tab-research.png';
import TabResearch2 from './img/tab/tab-research2.png';
import { Row48, Row, PageEnd, Column6, Card } from './Layout';
import TabVideo from './video/tab/tab.mov';

export const Tab = () => (
    <div className="row gy-xxl">
        <ProjectBanner src={TabBanner} alt="Tab" />

        <ProjectTitle title="AutoCAD Tab Redesign, Extended to Multi-brand Design System">
            <div className="row gx-xs gy-xs">
                <div className="col-auto">
                    <Tag text="UI/UX" />
                </div>
                <div className="col-auto">
                    <Tag text="Visual Design" />
                </div>
                <div className="col-auto">
                    <Tag text="Interaction Design" />
                </div>
                <div className="col-auto">
                    <Tag text="Prototyping" />
                </div>
                <div className="col-auto">
                    <Tag text="User Research" />
                </div>
            </div>
        </ProjectTitle>

        <Row48 title="Overview">
            AutoCAD is a CAD and drafting tool that enables users to create 2D,
            3D precise drawings, commonly used in architecture, engineering, and
            construction industries. AutoCAD team is planning to refresh their
            user interface with new theme colors with improved component
            designs. <br />
            <br />
            Given the complexity of AutoCAD's unique design pattern, I was
            tasked to redesign their tabs. Furthermore, there was a potential to
            contribute to the Weave, Autodesk design system, so other Autodesk
            products can adapt this component pattern, aligning the visual
            design.
        </Row48>

        <Row48 title="Role">
            As the sole designer, I lead this project to drive the redesign
            process from conception to implementation. This includes conducting
            research, collaboration with other teams to find common design
            patterns, creating new design examples, facilitating feedback
            sessions, prototyping with interactions and layout, and execution of
            the final design.
        </Row48>

        <Row48 title="Challenges">
            <ul>
                <li>
                    Complexity of tabs: Some of tab patterns in AutoCAD were too
                    unique, and it might be only used in AutoCAD. Ensuring that
                    some of designs can be product-specific design, had a
                    potential to become an exception and not contributed to the
                    Weave design system.
                </li>
                <li>
                    Technical difficulty: Current design don’t have hover effect
                    on tab. It might be challenging to implement the redesigned
                    tab components since it may cause problem with existing
                    codebase.
                </li>
                <li>
                    Cross-product compatibility: Each Autodesk products has
                    unique users from diverse background and industries, with
                    unique preferences and workflows. The redesign needs to
                    ensure compatibility and consistency across different
                    products while still meeting specific requirements from each
                    product.
                </li>
            </ul>
        </Row48>

        <Row title="Design Process">
            <NoFrame src={TabProcess} />
        </Row>

        <Row48 title="Audit/User Research">
            Firstly, I conducted research session with AutoCAD UX team to
            understand AutoCAD's design pattern. I started to analyze the
            existing tab design patterns to understand the function and purpose
            of different tab styles. Some of tab patterns were product-specific,
            so I collaborated with AutoCAD research team to get deep insight by
            getting feedback and user flows from external users. <br />
            <br />
            Dido Tsigaridi who is Sr. UX research, and Karen Mason who is Sr.UX
            designer in AutoCAD Team did 1 hr long 1:1 sessions with 5 customers
            to understand the current use of 'layout tabs' and 'color tabs.
            <br />
            <br />
            Since the redesign tab can be used in other Autodesk products, I
            also did some audit asking other product teams to provide all of
            their tab patterns, getting some insight about the common design
            patterns.
        </Row48>

        <div className="px-s mt-m">
            <NoFrame src={TabResearch1} />
        </div>

        <div className="px-s mt-m">
            <NoFrame src={TabResearch2} />
        </div>

        <Row title="Key Findings">
            <div>
                <div className="row py-xxl px-xl gx-xl px-lg-xxl gx-lg-xxl gy-l">
                    <Column6>
                        <Card title="Canvas Tabs">
                            Many Autodesk desktop products have canvas tab.
                            AutoCAD canvas tab had unique design because they
                            wanted to have visual separation to differentiate
                            the canvas tab from other tool tabs.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Additional States">
                            Some of states from research are product-specific
                            features, but I still need to think about including
                            all of special states as optional states.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Accessibility">
                            New tab design should be designed with
                            accessibility, ensuring that understandable for all
                            users including people with disabilities. This may
                            involve color contrast, keyboard navigation, or
                            screen compatibility.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Usability">
                            A lot of Autodesk desktop products are
                            information-dense applications, meaning that it is
                            common to have some usability issues such as crowded
                            designs with truncation options, small sizes and
                            spaces, or inconsistent behaviors.
                        </Card>
                    </Column6>
                </div>
            </div>
        </Row>

        <Row title="Design Exploration">
            <div className="px-m px-md-xxl">
                <NoFrame src={TabExploration1} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={TabExploration2} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={TabExploration3} />
            </div>
            <div className="px-m px-md-xxl">
                <NoFrame src={TabExploration4} />
            </div>
        </Row>

        <Row title="Prototyping">
            <div className="col-12">
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${TabVideo}#t=0.001`}
                    type="video/mp4"
                />
            </div>
        </Row>

        <Row title="Final Design">
            <NoFrame src={TabFinalDesign} />
        </Row>

        <Row48 title="Learnings and Relection">
            <div className="row gy-xl">
                <KeyFinding title="Collaboration matters!">
                    This project gave me valuable lesson about effective
                    collaboration across cross-functional teams including
                    product designers, developers, researchers, and managers. I
                    spent lots of time participating in meetings, making sure to
                    align the project goals from diverse perspectives.
                </KeyFinding>
                <KeyFinding title="Adaptability and flexibility">
                    As I spent lots of time exploring and understanding other
                    products, I recognized the diverse needs and preferences
                    from different users. It highlighted the importance of
                    making flexible, adaptable component in order to become a
                    component for multi-brand design system.
                </KeyFinding>
                <KeyFinding>
                    During this project, I was able to lead from initiating
                    concept, ideas, collaboration with other teams, facilitating
                    feedback sessions, building prototype, to executing the
                    final design. Through leading this project, I gained
                    valuable experience in all aspects of the design process.
                </KeyFinding>
            </div>
        </Row48>

        <PageEnd />
    </div>
);
